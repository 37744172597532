import { datadogRum } from "@datadog/browser-rum";
import { Identity, useIdentity } from "@equipmentshare/auth0-react";
import { useEffect } from "react";

export const useDatadogIdentify = (): boolean => {
  const identity: Identity = useIdentity();

  useEffect(() => {
    if (!datadogRum.getUser().id) {
      datadogIdentify(identity);
    }
  }, [identity]);

  return Boolean(datadogRum.getUser().id);
};

export const datadogIdentify = (identity: Identity) => {
  datadogRum.setUser({
    id: `${identity.fullName} (${identity.userId})`,
    email: identity.email,
    user_id: identity.userId,
    user_name: identity.fullName,
    company_id: identity.companyId,
  });
};
