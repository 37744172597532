/* eslint-disable jest/require-hook */
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";

import { useFlag } from "~/LaunchDarkly";

declare global {
  interface Window {
    appletDebug: typeof console.info;
  }
}

window.appletDebug = () => {
  // do nothing
};

const Applet: React.FC = () => {
  const hasAuthorization = useFlag("applet-debug");

  useEffect(() => {
    if (hasAuthorization) {
      window.appletDebug = (message) => console.info("[debug]", message);
      window.appletDebug("Debug Applet mounted successfully");

      return () => {
        window.appletDebug("Debug Applet unmounting...");
        window.appletDebug = () => {
          // do nothing
        };
      };
    }
  }, [hasAuthorization]);

  return null;
};

export const mount = (el: HTMLDivElement) => {
  const root = createRoot(el);
  root.render(<Applet />);
};
