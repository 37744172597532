import { Article, WithContext } from "schema-dts";

import { EQUIPMENTSHARE_BASE_URL } from "../constants";

import { ArticleJson } from "./types";

import { stripHtmlTags } from "~/utils";

export const buildStructuredData = (
  article: ArticleJson
): WithContext<Article> => {
  const structuredData: WithContext<Article> = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: article.name,
    datePublished: article.publish_date,
    dateModified: article.publish_date,
    articleBody: stripHtmlTags(article.article_body),
    url: `${EQUIPMENTSHARE_BASE_URL}/articles/${article.slug}`,
  };

  const image = article.main_image?.url;
  if (image) {
    structuredData.image = image;
  }

  return structuredData;
};
