export const makeCanonicalUrl = (location: URL): string => {
  const { pathname: _pathname, search } = location;
  const pathname = _pathname.replace(/\/$/i, "");
  const params = new URLSearchParams(search);

  const job_id = params.get("gh_jid");
  const careersRegex = /^\/careers\/openings\/?$/i;
  if (job_id && careersRegex.test(pathname)) {
    //assign careers canon their greenhouse id

    return `${pathname}?gh_jid=${job_id}`;
  }

  return pathname;
};
