import { theme } from "antd";
import React from "react";
import styled, { css, RuleSet } from "styled-components";

import { ThemeName } from "./index";

import { BRAND_BOOK_COLORS } from "./configs/equipmentshare";
import { TRADE_GOTHIC_NEXT_FONT_CSS_HREF } from "./constants";

import { TokenProps } from "~/types";

/**
 * For additional animations, see github.com/animate-css/animate.css/blob/main/source/animate.css
 */

type GlobalStylesProps = {
  $reset?: boolean;
  $themeName?: ThemeName;
  $darkMode?: boolean;
};

export const GlobalStyles: React.FC<GlobalStylesProps> = (props) => {
  const { token } = theme.useToken();

  return (
    <React.Fragment>
      {props.$themeName === "EquipmentShare" &&
        !document.querySelector(
          `link[href="${TRADE_GOTHIC_NEXT_FONT_CSS_HREF}"]`
        ) && <link rel="stylesheet" href={TRADE_GOTHIC_NEXT_FONT_CSS_HREF} />}
      <GlobalStylesDiv {...props} $token={token} />
    </React.Fragment>
  );
};

const GlobalStylesDiv = styled.div<GlobalStylesProps & TokenProps>`
  ${({ $reset }) => ($reset === false ? "" : "all: unset;")}

  ::selection {
    color: ${BRAND_BOOK_COLORS.WHITE};
    background: ${BRAND_BOOK_COLORS.ORANGE_PRIMARY};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  div,
  p {
    text-transform: unset;
    letter-spacing: 0px;
  }

  .ant-steps-item-finish .ant-steps-icon svg {
    color: ${({ $token }) => $token.colorWhite};
  }

  .ant-alert-message {
    whitespace: normal;
  }

  ${({ $themeName, $token }) => {
    // console.info("theme", $token);
    const rules: Array<RuleSet> = [];

    if ($themeName === "EquipmentShare") {
      rules.push(css`
        .ant-btn {
          text-decoration: inherit;
          text-transform: uppercase;
          border-width: 1px;
          border-style: solid;
        }

        input,
        textarea {
          border-width: 0.75px;
          padding: 12px !important;
        }
        .ant-select-auto-complete {
          margin-bottom: 18px;
        }
        .ant-form-item-explain {
          padding: 5px;
        }
        .ant-form-item-control-input {
          align-items: inherit;
        }

        .ant-select {
          &.ant-select-single {
            height: 48px;
          }

          &:hover,
          &.ant-select-open,
          &.ant-select-focused {
            .ant-select-selector {
              border-color: ${$token.colorText} !important;
            }
          }
          .ant-select-selector:has(
              .ant-select-selection-search + .ant-select-selection-item
            ),
          .ant-select-selector:has(.ant-select-selection-search) {
            padding: 24px 11px !important;
            border-width: 0.75px;
            height: inherit;
          }
          .ant-select-selector:not(
              :has(.ant-select-selection-search + .ant-select-selection-item)
            )
            + .ant-select-clear {
            top: 24px;
          }
          .ant-select-selection-search {
            inset-inline-start: 0 !important;
            inset-inline-end: 0 !important;
          }
          .ant-select-selector + .ant-select-arrow,
          .ant-select-arrow + .ant-select-clear {
            top: 24px;
          }
          .ant-select-selection-item {
            padding: 8px 0;
          }
        }
      `);
    }

    if ($themeName === "T3") {
      rules.push(css``);
    }

    return rules;
  }}

  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeInFast {
    animation: fadeInAnimation ease-in-out 200ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }

  .zoomInFast {
    animation: zoomIn ease-in-out 100ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .bounceInFast {
    animation: bounceIn ease-in-out 500ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
`;
