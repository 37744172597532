import { Offer, WithContext } from "schema-dts";

import { COUNTRY_SERVED, ORGANIZATION_SCHEMA } from "../constants";

export const RENTAL_DAILY_OFFER: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "Offer",
  availability: "InStock",
  name: "Daily Rental",
  areaServed: COUNTRY_SERVED,
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 1,
    maxValue: 6,
    unitCode: "DAY",
  },
  offeredBy: ORGANIZATION_SCHEMA,
  businessFunction: {
    "@id": "http://purl.org/goodrelations/v1#LeaseOut",
  },
  price: 0,
  priceCurrency: "USD",
};

export const RENTAL_WEEKLY_OFFER: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "Offer",
  availability: "InStock",
  name: "Weekly Rental",
  areaServed: COUNTRY_SERVED,
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 7,
    maxValue: 27,
    unitCode: "DAY",
  },
  offeredBy: ORGANIZATION_SCHEMA,
  businessFunction: {
    "@id": "http://purl.org/goodrelations/v1#LeaseOut",
  },
  price: 0,
  priceCurrency: "USD",
};

export const RENTAL_MONTHLY_OFFER: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "Offer",
  availability: "InStock",
  name: "4-week Rental",
  areaServed: COUNTRY_SERVED,
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 28,
    unitCode: "DAY",
  },
  offeredBy: ORGANIZATION_SCHEMA,
  businessFunction: {
    "@id": "http://purl.org/goodrelations/v1#LeaseOut",
  },
  price: 0,
  priceCurrency: "USD",
};
