import { FeatureFlag, FeatureFlagMap } from "./types";
import { useFlags } from "./useFlags";

export const useFlag = <T extends FeatureFlag>(
  flagKey: T
): undefined | FeatureFlagMap[T] => {
  const flags = useFlags();

  if (flags) {
    if (flags[flagKey] !== undefined) {
      return flags[flagKey];
    } else {
      console.log(flags);
      throw new Error(`"${flagKey}" is not a valid LaunchDarkly feature flag!`);
    }
  }

  return undefined;
};
