import { KEYWORD_LINKER_ATTRIBUTE_NAME } from "./constants";
import { KeywordRef } from "./types";

export const makeMutationObserverCallback =
  (handleKeywordLinkerElement: (el: HTMLElement) => void): MutationCallback =>
  (mutations) => {
    mutations.forEach((mutation) => {
      // console.info(mutation);

      const walker = document.createTreeWalker(
        mutation.target,
        NodeFilter.SHOW_ELEMENT
      );

      let node = walker.nextNode();

      while (node) {
        if (node.nodeType === 1) {
          const el = node as HTMLElement;
          if (el.getAttribute(KEYWORD_LINKER_ATTRIBUTE_NAME)) {
            handleKeywordLinkerElement(el);
          }

          node = walker.nextNode();
        }
      }
    });
  };

export const keywordLinkElement = (
  el: Element,
  keywords: Array<KeywordRef>
) => {
  // console.info(el, keywords);
  const hrefTypes = el.getAttribute(KEYWORD_LINKER_ATTRIBUTE_NAME)?.split(",");

  keywords.forEach((keyword) => {
    hrefTypes?.forEach((hrefType) => {
      const hrefTypeKey = `${hrefType}_href` as keyof typeof keyword;

      /**
       * create an array of strings that will either be:
       *   1. start tag
       *   2. end tag
       *   3. plain text
       */
      const rawHtml = el.innerHTML;
      const tokens = rawHtml.match(/(<.*?>|[^<>]+)/g);

      if (tokens) {
        let aTag = false;
        const matchedRegExpStrings: Array<string> = [];

        const newTokens = tokens.map((token) => {
          if (/<a\s.*?>/i.test(token)) {
            aTag = true;
          } else if (/<\/a>/i.test(token)) {
            aTag = false;
          } else if (
            !aTag &&
            keyword[hrefTypeKey] &&
            !matchedRegExpStrings.includes(keyword.regular_expression) &&
            !rawHtml.includes(keyword[hrefTypeKey])
          ) {
            const regex = new RegExp(
              `\\b(${keyword.regular_expression})\\b`,
              "i"
            );

            if (regex.test(token)) {
              matchedRegExpStrings.push(keyword.regular_expression);

              return token.replace(
                regex,
                `<a href="${keyword.category_href}" target="_blank">$1</a>`
              );
            }
          }

          return token;
        });

        el.innerHTML = newTokens.join("");
      }
    });
  });
};
