import * as t3token from "@equipmentshare/t3-design-tokens";
import { theme } from "antd";
import {
  MappingAlgorithm,
  MapToken,
  PresetColorType,
} from "antd/es/theme/interface";

import { AntColorIndex, T3ColorIndex } from "./types";

export const themeMappingAlgorithm: MappingAlgorithm = (token): MapToken => {
  const mapping: MapToken = theme.defaultAlgorithm(token);

  const tokenMap: Partial<
    Record<keyof PresetColorType, keyof (typeof t3token)["COLOR_SCALE"]>
  > = {
    blue: "PRIMARY",
    green: "SUCCESS",
    red: "DANGER",
    yellow: "WARNING",
    pink: "ACCENT",
    magenta: "ACCENT",
  };

  (Object.keys(tokenMap) as Array<keyof typeof tokenMap>).forEach((prefix) => {
    const colorScale = tokenMap[prefix]!;

    (
      Object.keys(
        t3token.COLOR_SCALE[colorScale]
      ) as unknown as Array<T3ColorIndex>
    ).forEach((k) => {
      const antIdx = (parseInt(k) / 100) as AntColorIndex;
      const t3colorToken = t3token.COLOR_SCALE[colorScale][k];
      mapping[`${prefix}-${antIdx}`] = t3colorToken;
      mapping[`${prefix}${antIdx}`] = t3colorToken;

      // t3 only has 9 tokens, but ant has 10--make the two darkest ant colors (9 and 10) the same t3 token (900)
      if (k === "900") {
        mapping[`${prefix}-10`] = t3colorToken;
        mapping[`${prefix}10`] = t3colorToken;
      }
    });
  });

  return mapping;
};
