import { ConfigProvider, ThemeConfig } from "antd";
import styled from "styled-components";

import { themeConfig as esThemeConfig } from "./configs/equipmentshare";
import { themeConfig as t3ThemeConfig } from "./configs/t3";

export { GlobalStyles } from "./GlobalStyles";
export { COLOR_SHADE } from "@equipmentshare/t3-design-tokens";

export type ThemeName = "T3" | "EquipmentShare";

export const themeConfig: Record<ThemeName, ThemeConfig> = {
  T3: t3ThemeConfig,
  EquipmentShare: esThemeConfig,
};

/**exportVariable
 * Applets should be wrapped in this component to automatically apply CSS
 *
 * For additional animations, see github.com/animate-css/animate.css/blob/main/source/animate.css
 * */
export const AppletWrapper = styled(ConfigProvider).attrs({
  as: "div",
  theme: themeConfig,
})`
  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation: fadeInAnimation ease-in-out 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;
