import { Product, WithContext } from "schema-dts";

import { EQUIPMENTSHARE_BASE_URL } from "../constants";

import { EquipmentClassJson } from "./types";

import { stripHtmlTags } from "~/utils";

export const buildStructuredDataFromEquipmentClass = (
  equipmentClass: EquipmentClassJson
): WithContext<Product> => ({
  "@context": "https://schema.org",
  "@type": "Product",
  name: equipmentClass.name,
  description: stripHtmlTags(equipmentClass.description),
  image: equipmentClass.primary_image?.url,
  url: `${EQUIPMENTSHARE_BASE_URL}/rent/equipment-classes/${equipmentClass.slug}`,
});
