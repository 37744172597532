import React, { useEffect } from "react";

import { ObserverProps } from "./types";
import { keywordLinkElement, makeMutationObserverCallback } from "./utils";

export const Observer: React.FC<ObserverProps> = ({ keywords }) => {
  useEffect(() => {
    document.querySelectorAll("[keyword-linker]").forEach((el) => {
      keywordLinkElement(el, keywords);
    });

    // check for new mount points on the DOM with the browser native MutationObserver interface
    const mutationCallback: MutationCallback = makeMutationObserverCallback(
      (el) => {
        // if (isDevelopment) {
        //   console.info("keywordLinkerElementCallback", el);
        // }
        keywordLinkElement(el, keywords);
      }
    );

    const observer = new MutationObserver(mutationCallback);

    observer.observe(document.body, {
      attributeFilter: ["keyword-linker"],
      childList: true,
      characterData: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return null;
};
