import { Article, WithContext } from "schema-dts";

import { EQUIPMENTSHARE_BASE_URL } from "../constants";

import { PressJson } from "./types";

import { stripHtmlTags } from "~/utils";

export const buildStructuredData = (press: PressJson): WithContext<Article> => {
  const structuredData: WithContext<Article> = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: press.name,
    datePublished: press.publish_date,
    dateModified: press.publish_date,
    articleBody: stripHtmlTags(press.section_1_body),
    url: `${EQUIPMENTSHARE_BASE_URL}/press-releases/${press.slug}`,
  };

  const image = press.main_image?.url;
  if (image) {
    structuredData.image = image;
  }

  return structuredData;
};
