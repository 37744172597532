import { datadogRum } from "@datadog/browser-rum";
import { initialize, LDEvaluationDetail } from "launchdarkly-js-client-sdk";

import pkg from "~/../package.json";
import { env, isPrerender } from "~/env";

export const ldClient = initialize(
  env("LAUNCH_DARKLY_CLIENT_ID")!,
  {
    kind: "user",
    anonymous: true,
  },
  {
    fetchGoals: false,
    // disable streaming for prerender, otherwise network never goes idle
    streaming: !isPrerender,
    sendEvents: !isPrerender,
    application: {
      id: "omnichannel-applets",
      version: pkg.version,
    },
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  }
);
