/* eslint-disable jest/require-hook */

type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
  userChoice: () => Promise<"accepted" | "dismissed">;
};

export let deferredPrompt: undefined | BeforeInstallPromptEvent;

window.addEventListener("beforeinstallprompt", (e) => {
  if (e.cancelable) {
    e.preventDefault();
  }
  deferredPrompt = e as BeforeInstallPromptEvent;
});

export const installPwa = async () => {
  if (deferredPrompt) {
    await deferredPrompt.prompt();
    await deferredPrompt.userChoice;
    deferredPrompt = undefined;
  }
};

window.addEventListener("appinstalled", () => {
  deferredPrompt = undefined;
});

export const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;

  if (document.referrer.startsWith("android-app://")) {
    return "twa";
    // @ts-expect-error navigator.standalone not typed
  } else if (navigator.standalone || isStandalone) {
    return "standalone";
  }

  return "browser";
};
