/* eslint-disable jest/require-hook */
import OpenAPIClientAxios, {
  AxiosRequestConfig,
  Document,
} from "openapi-client-axios";
import Qs from "qs";

import { Client } from "./openapi";
import definition from "./openapi.json";

import { isProduction } from "~/env";

export const makeOpenApiClient = (
  headers?: AxiosRequestConfig["headers"]
): Client => {
  const apiClient = new OpenAPIClientAxios({
    definition: definition as unknown as Document,
    withServer: isProduction ? 1 : 0,
    axiosConfigDefaults: {
      withCredentials: false,
      paramsSerializer: (params) =>
        Qs.stringify(params, { arrayFormat: "brackets" }),
      headers,
    },
  });

  return apiClient.initSync<Client>();
};

export const addInterceptors = (client: Client) => {
  client.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      try {
        if (
          window.notifyUser &&
          (["post", "put", "patch"].includes(
            err.config?.method?.toLowerCase() || ""
          ) ||
            window.location.host !== "www.equipmentshare.com")
        ) {
          window.notifyUser("error", {
            message: "An error has occurred.",
            description: err.message,
          });
        }
      } catch (e) {
        // do nothing
      }

      throw err;
    }
  );
};
