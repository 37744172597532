import { ThemeConfig } from "antd";
import { merge } from "lodash-es";

import { themeConfig as sharedThemeConfig } from "../shared";

import { TRADE_GOTHIC_NEXT_FONT_FAMILY } from "~/Constant/Theme/constants";

export const BRAND_BOOK_COLORS = {
  BLACK: "#24242D",
  BLACK_6C: "#14141C",
  GRAY_DARK: "#A9A9A9",
  GRAY_LIGHT: "#D9D9D9",
  WHITE_EGGSHELL: "#F9F9F6",
  WHITE: "#FFFFFF",
  ORANGE_PRIMARY: "#FD6600",
  BOX_SHADOW: "0 4px 10px #0000001a",
};

const PRIMARY_COLOR = "#F16622";
const PRIMARY_HOVER_COLOR = "#D8500E";
const SUCCESS_COLOR = "#00861D";
const TEXT_COLOR = "#1A1C21";
const INFO_COLOR = BRAND_BOOK_COLORS.BLACK;
const TRACE_COLOR = "#A9A9A9";
const LINK_COLOR = "#AD4702";

export const esTheme: ThemeConfig = {
  token: {
    borderRadius: 4,
    colorPrimary: PRIMARY_COLOR,
    colorTextSecondary: INFO_COLOR,
    fontFamily: TRADE_GOTHIC_NEXT_FONT_FAMILY,
    fontFamilyCode: TRADE_GOTHIC_NEXT_FONT_FAMILY,
    boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.10)",
    colorTextTertiary: TRACE_COLOR,
    colorLink: LINK_COLOR,
  },
  components: {
    Button: {
      colorPrimaryHover: PRIMARY_HOVER_COLOR,
      colorPrimary: PRIMARY_COLOR,
      colorLink: PRIMARY_COLOR,
      colorLinkHover: PRIMARY_HOVER_COLOR,
      linkDecoration: "underline",
    },
    Input: {
      colorBorder: TRACE_COLOR,
      borderRadius: 4,
      hoverBorderColor: TEXT_COLOR,
      activeBorderColor: TEXT_COLOR,
    },
    Select: {
      colorBorder: TRACE_COLOR,
      borderRadius: 4,
      optionPadding: "8px 16px",
    },
    InputNumber: {
      borderRadius: 4,
      addonBg: INFO_COLOR,
      colorBorder: TRACE_COLOR,
      hoverBorderColor: TEXT_COLOR,
      activeBorderColor: TEXT_COLOR,
    },
    Typography: {
      fontSizeHeading3: 20,
      lineHeightHeading3: 1.3,
    },
    Steps: {
      colorSplit: TRACE_COLOR,
      controlItemBgActive: SUCCESS_COLOR,
      colorText: TEXT_COLOR,
      colorPrimary: TEXT_COLOR,
      fontSize: 16,
    },
    Slider: {
      handleColor: INFO_COLOR,
      trackBg: INFO_COLOR,
      trackHoverBg: INFO_COLOR,
      handleActiveColor: INFO_COLOR,
    },
    Progress: {},
  },
};

export const themeConfig: ThemeConfig = merge({}, sharedThemeConfig, esTheme);
