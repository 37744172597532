/* eslint-disable jest/require-hook */
import { notification } from "antd";
import React from "react";
import { createRoot } from "react-dom/client";

import { NotifyUser } from "./types";

import { AppletWrapper } from "~/Atom/AppletWrapper";

declare global {
  interface Window {
    notifyUser: NotifyUser;
  }
}

const Applet: React.FC = () => {
  // see global config options: https://ant.design/components/notification#global-configuration
  const [api, contextHolder] = notification.useNotification({});

  const notifyUserFn: NotifyUser = (type, args) => {
    api[type](args);
  };

  window.notifyUser = window.notifyUser || notifyUserFn;

  return (
    <AppletWrapper appletName="notifications">{contextHolder}</AppletWrapper>
  );
};

export const mount = (el: HTMLDivElement) => {
  const root = createRoot(el);
  root.render(<Applet />);
};
