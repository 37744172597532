import React from "react";
import { WebSite, WithContext } from "schema-dts";

import { ORGANIZATION_SCHEMA } from "../constants";
import { JSONLDComponent } from "../JSONLDComponent";

export const WebSiteSchema: React.FC = () => {
  const webSiteData: WithContext<WebSite> = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "EquipmentShare Website",
    url: window.location.origin,
    copyrightYear: new Date().getFullYear(),
    isFamilyFriendly: true,
    maintainer: ORGANIZATION_SCHEMA,
  };

  return <JSONLDComponent structuredData={webSiteData} />;
};
