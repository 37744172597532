import { useEffect, useState } from "react";

/** Trigger a re-render when window is focused */
export const useWindowFocus = () => {
  const [timeStamp, setTimeStamp] = useState<number>();

  // forces render and refresh token auth when focusing
  useEffect(() => {
    const callback = (evt: Event) => setTimeStamp(evt.timeStamp);

    window.addEventListener("focus", callback);

    return () => window.removeEventListener("focus", callback);
  }, []);

  return timeStamp;
};
