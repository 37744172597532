import {
  DayOfWeek,
  HomeAndConstructionBusiness,
  Offer,
  OpeningHoursSpecification,
  Service,
  WithContext,
} from "schema-dts";

import { EQUIPMENTSHARE_BASE_URL } from "../constants";

import { LocationJson } from "./types";

import { isTruthy, stripHtmlTags } from "~/utils";
export const makeHoursObject = (
  dayOfWeek: DayOfWeek,
  hours: string
): OpeningHoursSpecification | undefined => {
  if (/^closed$/i.test(hours.trim())) {
    return;
  }

  const terms = hours.split(" - ");

  return {
    "@type": "OpeningHoursSpecification",
    opens: convertTime(terms[0])!,
    closes: convertTime(terms[1])!,
    dayOfWeek: dayOfWeek,
  };
};

export const convertTime = (timeString: string) => {
  const regex = /^(\d+):(\d+)(AM|PM)$/i;
  const timeTerms = timeString.match(regex);
  if (timeTerms?.length !== 4) {
    throw new Error(
      `${timeString} timeString is not properly formatted for json+ld!`
    );
  }

  const hr = timeTerms[1].trim();
  const min = timeTerms[2].trim();
  const period = timeTerms[3].trim();
  if (period === "AM") {
    return [parseInt(hr) < 10 ? `0${hr}` : hr, min].join("");
  } else if (period === "PM") {
    return [Number(hr) + 12, min].join("");
  }
};

export const openingHoursMap = (
  data: LocationJson
): Array<OpeningHoursSpecification> => {
  return (
    [
      ["Monday", data.hours_of_operation_monday],
      ["Tuesday", data.hours_of_operation_tuesday],
      ["Wednesday", data.hours_of_operation_wednesday],
      ["Thursday", data.hours_of_operation_thursday],
      ["Friday", data.hours_of_operation_friday],
      ["Saturday", data.hours_of_operation_saturday],
      ["Sunday", data.hours_of_operation_sunday],
    ] as Array<[DayOfWeek, string]>
  )
    .map((day) => makeHoursObject(...day))
    .filter(isTruthy);
};

export const buildStructuredData = (
  location: LocationJson
): WithContext<HomeAndConstructionBusiness> => {
  const itemListElement: Array<Offer> = [];

  location.markets.forEach((market) =>
    market.rental_services.forEach((service) => {
      if (
        itemListElement.some(
          (el) => (el.itemOffered as Service)?.name === service.name
        )
      ) {
        return;
      }

      itemListElement.push({
        "@type": "Offer",
        itemOffered: {
          "@type": "Service",
          name: service.name,
          description: stripHtmlTags(service.rich_text_content),
        },
        areaServed: {
          "@type": "GeoCircle",
          geoMidpoint: {
            "@type": "GeoCoordinates",
            latitude: location.latitude,
            longitude: location.longitude,
          },
          geoRadius: `${location.delivery_radius_miles} miles`,
        },
      });
    })
  );

  const structuredData: WithContext<HomeAndConstructionBusiness> = {
    "@context": "https://schema.org",
    "@type": "HomeAndConstructionBusiness",
    name: location.name,
    description: stripHtmlTags(location.description),
    branchCode: String(
      location.markets.reduce((prev, curr) =>
        (prev.solution.at(0)?.slug ?? "") < (curr.solution.at(0)?.slug ?? "")
          ? prev
          : curr
      )?.slug || ""
    ),
    url: `${EQUIPMENTSHARE_BASE_URL}/locations/${location.slug}`,
    address: {
      "@type": "PostalAddress",
      streetAddress: location.location_address,
      addressLocality: location.city,
      addressRegion: location.region_state_abbreviation,
      postalCode: location.postal_code,
      addressCountry: "US",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: location.latitude,
      longitude: location.longitude,
    },
    telephone: location.location_phone,
    openingHoursSpecification: openingHoursMap(location),
    hasOfferCatalog: {
      "@type": "OfferCatalog",
      name: "Rental Services",
      itemListElement,
    },
  };

  return structuredData;
};
