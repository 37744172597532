import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { Observer } from "./Observer";
import { KeywordRef } from "./types";
import { makeMutationObserverCallback } from "./utils";

import { isAppletPicker, isDevelopment } from "~/env";
import { useFlag } from "~/LaunchDarkly";
import { useOmnichannelApi } from "~/OmnichannelApi";

const Applet: React.FC = () => {
  const hasAuthorization = useFlag("applet-es-keyword-linker");
  const api = useOmnichannelApi();

  const [keywords, setKeywords] = useState<Array<KeywordRef>>();
  const [shouldFetchKeywords, setShouldFetchKeywords] =
    useState<boolean>(false);
  const [isFetchingKeywords, setIsFetchingKeywords] = useState<boolean>(false);
  const [observer, setObserver] = useState<MutationObserver>();

  useEffect(() => {
    if (shouldFetchKeywords) {
      observer?.disconnect();
    } else {
      // check for new mount points on the DOM with the browser native MutationObserver interface
      const mutationCallback: MutationCallback = makeMutationObserverCallback(
        () => {
          // if (isDevelopment) {
          //   console.info("keywordLinkerCallback", el);
          // }
          setShouldFetchKeywords(true);
        }
      );

      const localObserver = new MutationObserver(mutationCallback);

      localObserver.observe(document.body, {
        attributeFilter: ["keyword-linker"],
        childList: true,
        subtree: false,
      });

      setObserver(localObserver);
    }

    return () => observer?.disconnect();
  }, [shouldFetchKeywords]);

  useEffect(() => {
    if (
      hasAuthorization &&
      !keywords?.length &&
      shouldFetchKeywords &&
      !isFetchingKeywords
    ) {
      setIsFetchingKeywords(true);
      api["static.webflow.collections.items.get"]({
        collection_slug: "keyword-references",
        path_name: "keyword-linker",
        item_slug: "_all",
        file_type: "json",
      })
        .then((res) => {
          setKeywords(res.data as Array<KeywordRef>);
        })
        .catch(console.error);
      // do not setIsFetchingKeywords(false) or it could trigger infinite loop of failing requests
    }
  }, [hasAuthorization, shouldFetchKeywords, isFetchingKeywords]);

  return hasAuthorization && keywords?.length ? (
    <Observer keywords={keywords} />
  ) : null;
};

export const mount = async (el: HTMLDivElement) => {
  if ((isDevelopment || isAppletPicker) && el.getAttribute("applet-picker")) {
    const elLorem = document.createElement("div");
    document.body.appendChild(elLorem);

    (await import("./Tester")).mount(elLorem);
  } else {
    const root = createRoot(el);
    root.render(<Applet />);
  }
};
