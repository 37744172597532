import * as t3token from "@equipmentshare/t3-design-tokens";
import { ThemeConfig } from "antd";
import { merge } from "lodash-es";

import { themeConfig as sharedThemeConfig } from "../shared";

import { themeMappingAlgorithm } from "./algorithm";

const THEME_COLOR_WEIGHT = 700;
const PRIMARY_COLOR = t3token.COLOR_SCALE.PRIMARY[THEME_COLOR_WEIGHT];
const ERROR_COLOR = t3token.COLOR_SCALE.DANGER[THEME_COLOR_WEIGHT];
const SUCCESS_COLOR = t3token.COLOR_SCALE.SUCCESS[THEME_COLOR_WEIGHT];
const INFO_COLOR = t3token.COLOR_SCALE.ACCENT[THEME_COLOR_WEIGHT];
const WARNING_COLOR = t3token.COLOR_SCALE.WARNING[THEME_COLOR_WEIGHT];

export const t3theme: ThemeConfig = {
  algorithm: themeMappingAlgorithm,
  token: {
    colorPrimary: PRIMARY_COLOR,
    colorError: ERROR_COLOR,
    colorSuccess: SUCCESS_COLOR,
    colorInfo: INFO_COLOR,
    colorWarning: WARNING_COLOR,
    borderRadius: 6,
    fontFamily: "trade-gothic-next, arial, sans-serif",
    fontFamilyCode: "trade-gothic-next, arial, sans-serif",
    boxShadow:
      "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12)",
    boxShadowSecondary:
      "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12)",
    boxShadowTertiary:
      "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
  },
  components: {
    Button: {
      defaultShadow: "none",
      primaryShadow: "none",
      dangerShadow: "none",
    },
    Form: {
      labelColor: t3token.COLOR_SHADE.FULL,
      colorError: t3token.COLOR_SCALE.DANGER[500],
    },
    Progress: {
      colorInfo: PRIMARY_COLOR,
    },
    Steps: {
      controlItemBgActive: SUCCESS_COLOR,
    },
  },
};

export const themeConfig: ThemeConfig = merge({}, sharedThemeConfig, t3theme);
