import React, { useEffect, useState } from "react";
import { Article, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { ArticleJson } from "./types";
import { buildStructuredData } from "./utils";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const ArticleSchema: React.FC = () => {
  const api = useOmnichannelApi();

  const [articleData, setArticleData] = useState<WithContext<Article>>();

  useEffect(() => {
    const matches = window.location.pathname.match(/^\/articles\/([^/]+)/i);

    if (matches) {
      const [, item_slug] = matches;

      api["static.webflow.collections.items.get"]({
        collection_slug: "articles",
        path_name: "items",
        item_slug,
        file_type: "json",
      }).then(({ data }) => {
        setArticleData(buildStructuredData(data as ArticleJson));
      });
    }
  }, []);

  if (!articleData) {
    return null;
  }

  return <JSONLDComponent structuredData={articleData} />;
};
