import React from "react";
import { Thing } from "schema-dts";

export const JSONLDComponent: React.FC<{ structuredData: Thing }> = ({
  structuredData,
}) => (
  <script
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(structuredData),
    }}
    type="application/ld+json"
  ></script>
);
