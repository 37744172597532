import { useEffect, useState } from "react";

import { FeatureFlagMap, ldClient } from "./index";

export const useFlags = (): undefined | FeatureFlagMap => {
  const [flags, setFlags] = useState<FeatureFlagMap>(
    ldClient.allFlags() as FeatureFlagMap
  );

  useEffect(() => {
    const updateFlags = () => setFlags(ldClient.allFlags() as FeatureFlagMap);

    ldClient.on("ready", updateFlags);
    ldClient.on("change", updateFlags);

    return () => {
      ldClient.off("ready", updateFlags);
      ldClient.off("change", updateFlags);
    };
  }, []);

  if (Object.keys(flags).length === 0) {
    return undefined;
  }

  return flags;
};
