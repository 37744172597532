import { Events } from "./events";

import { isProduction } from "~/env";

const IGNORE_HOST_ANALYTICS: Array<string> = [
  "omnichannel-applets.equipmentshare.com",
];

export const analyticsEvent = <T extends keyof Events>(
  eventName: T,
  eventParams: Events[T]
): void => {
  if (!isProduction || IGNORE_HOST_ANALYTICS.includes(location.host)) {
    console.info(
      `%cgtag%c ${eventName}`,
      `
      background-color: #1a73e8;
      color: white;
      border-radius: 10px;
      padding: 2px 5px;
      font-size: 90%;
     `,
      "font-weight: bold;",
      eventParams
    );
  } else if (gtag) {
    gtag("event", eventName, eventParams);
  } else {
    // do nothing...user could be blocking GA
  }
};
