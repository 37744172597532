import { AggregateOffer, WithContext } from "schema-dts";

import { EQUIPMENTSHARE_BASE_URL } from "../constants";

import { CategoryJson } from "./types";

import { stripHtmlTags } from "~/utils";

export const buildStructuredData = (
  category: CategoryJson
): WithContext<AggregateOffer> => ({
  "@context": "https://schema.org",
  "@type": "AggregateOffer",
  name: category.name,
  description: stripHtmlTags(category.description),
  image: category.primary_image?.url,
  url: `${EQUIPMENTSHARE_BASE_URL}/rent/categories/${category.slug}`,
  areaServed: {
    "@type": "Country",
    name: "US",
  },
});
