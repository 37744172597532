import React, { useEffect, useState } from "react";
import { Product, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import {
  RENTAL_DAILY_OFFER,
  RENTAL_MONTHLY_OFFER,
  RENTAL_WEEKLY_OFFER,
} from "./constants";
import { EquipmentClassJson } from "./types";
import { buildStructuredDataFromEquipmentClass } from "./utils";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const ProductSchema: React.FC = () => {
  const api = useOmnichannelApi();

  const [productData, setProductData] = useState<WithContext<Product>>();

  useEffect(() => {
    const matches = window.location.pathname.match(
      /^\/rent\/equipment-classes\/([^/]+)/i
    );

    if (matches) {
      const [, item_slug] = matches;

      api["static.webflow.collections.items.get"]({
        collection_slug: "equipment-classes",
        path_name: "items",
        item_slug,
        file_type: "json",
      }).then(({ data }) => {
        setProductData(
          buildStructuredDataFromEquipmentClass(data as EquipmentClassJson)
        );
      });
    }
  }, []);

  if (!productData) {
    return null;
  }

  return (
    <React.Fragment>
      <JSONLDComponent
        structuredData={{
          ...productData,
          offers: [
            RENTAL_DAILY_OFFER,
            RENTAL_WEEKLY_OFFER,
            RENTAL_MONTHLY_OFFER,
          ],
        }}
      />
    </React.Fragment>
  );
};
